<template>
    <div :class="data.Class" :style="data.Style">
        <img :src="data.Image" :style="data.ImageStyle" :class="data.ImageClass" />
    </div>
</template>
<script>
export default {
    props: {
        data: Object
    }
}
</script>
